import React from 'react'

const className = 'font-serif text-h3 md:text-h3md leading-tight antialiased'
export const H3ClassName = className

const H3: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  >
> = ({ children, className, ...other }) => {
  return (
    <h3
      className={H3ClassName + (className ? ` ${className}` : '')}
      {...other}
    >
      {children}
    </h3>
  )
}

export default H3
